import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'
import useScrollComment from '@/utils/useScrollComment'
import SystemCard, { SystemCardPropsType } from './systemCard'
import arrow from './assets/arrowLeft.png'
import arrowSm from './assets/arrowLeftSm.png'
import pic1 from './assets/pic1.png'
import pic2 from './assets/pic2.png'
import pic3 from './assets/pic3.png'
import pic4 from './assets/pic4.png'
import pic5 from './assets/pic5.png'
import pic6 from './assets/pic6.png'
import headBG from './assets/headBG.png'
import mask from './assets/mask.png'
import './system.less'

const cardStyle = {
  highlight: {
    css: {
      color: '#ff5500',
      boxShadow: '0px 8px 16px 0px rgba(199, 66, 0, 0.1)',
      border: '1px solid rgba(255, 85, 0, 0.4)',
    },
  },
  normal: {
    css: {
      color: '#000',
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
}

const DELAY_DISTANCE = 800
const DURATION_DISTANCE = 400

const cardDelayDistance = (num: number) =>
  DELAY_DISTANCE * num + DURATION_DISTANCE * (num - 0.5)
const cardDurationDistance = (num: number) =>
  DELAY_DISTANCE * (5 - num) + DURATION_DISTANCE * (4.5 - num)

const initHoverData = [true, false, false, false, false, false]

export default function System({ windowLg }: { windowLg: boolean }) {
  const [topIndex, setTopIndex] = useState(1)
  const [hoverList, setHoverList] = useState(initHoverData)

  const { handleClickComment, commentIndex } = useScrollComment({
    id: 'result',
    windowLg,
  })

  const handleClickItem = useCallback((id: number) => {
    setTopIndex(id)
    setHoverList(() => Array.from({ length: 6 }, (_, i) => i + 1 === id))
  }, [])

  const picData = useMemo(
    () => [
      {
        id: 1,
        title: '智能出题',
        content: 'AI智能精准出题，只练不会的，不练重复的。',
        pic: pic1,
        icon: 'question',
        isHover: hoverList[0],
      },
      {
        title: (
          <>
            AI批改
            <sup onClick={handleClickComment} style={{ cursor: 'pointer' }}>
              {commentIndex}
            </sup>
          </>
        ),
        id: 2,
        content: '一键AI自动批改，秒判错对，省时省力。',
        pic: pic2,
        icon: 'correct',
        isHover: hoverList[1],
      },
      {
        id: 3,
        title: '错题归纳',
        content: '错题自动收录整理，定期回顾，告别手动抄写粘贴的低效模式。',
        pic: pic3,
        icon: 'induction',
        isHover: hoverList[2],
      },
      {
        id: 4,
        title: '错题讲解',
        content:
          '错题分步骤引导解析，真人老师视频手把手启发解题，讲题过程还能倍数加速。',
        pic: pic4,
        icon: 'explain',
        isHover: hoverList[3],
      },
      {
        id: 5,
        title: '薄弱点专练',
        content:
          '精准定位薄弱点，自动生成个性化薄弱点专练，定期回顾攻克薄弱项。',
        pic: pic5,
        icon: 'practice',
        isHover: hoverList[4],
      },
      {
        id: 6,
        title: '举一反三',
        content: 'AI自动举一反三，一个知识点吃透练透。',
        pic: pic6,
        icon: 'analogy',
        isHover: hoverList[5],
      },
    ],
    [hoverList]
  )

  return (
    <>
      {windowLg ? (
        <section className="animate system-container-lg">
          {/* @ts-ignore */}
          <Controller>
            <div>
              {/* @ts-ignore */}
              <Scene
                triggerHook="onLeave"
                offset={-64}
                duration={5 * DURATION_DISTANCE + 6 * DELAY_DISTANCE}
                pin
              >
                {(progress: any) => (
                  <div className="system-main">
                    <h2 className="system-header-lg">
                      全场景「以练促学」精准学系统，薄弱点命中率
                      <span
                        className="system-highlight-lg"
                        style={{ backgroundImage: `url(${headBG})` }}
                      >
                        95%
                      </span>
                      以上
                    </h2>
                    <div className="system-body-lg">
                      <div className="system-left">
                        <div className="system-pic-hidden">
                          <img src={mask} className="system-mask" alt="mask" />
                          <img src={mask} className="system-mask" alt="mask" />
                          <div className="system-pic-container-lg">
                            <Timeline
                              target={<img src={pic1} alt="智能出题" />}
                              totalProgress={progress}
                              paused
                            >
                              <Tween
                                from={{ scale: 1 }}
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ scale: 0.89 }}
                                duration={DELAY_DISTANCE}
                              />
                            </Timeline>
                            <Timeline
                              target={<img src={pic2} alt="AI批改" />}
                              totalProgress={progress}
                              paused
                            >
                              <Tween
                                from={{ scale: 0.89 }}
                                to={{ xPercent: '-=100', scale: 1 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ scale: 0.89 }}
                                duration={DELAY_DISTANCE}
                              />
                            </Timeline>
                            <Timeline
                              target={<img src={pic3} alt="错题归纳" />}
                              totalProgress={progress}
                              paused
                            >
                              <Tween
                                from={{ scale: 0.89 }}
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 1 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ scale: 0.89 }}
                                duration={DELAY_DISTANCE}
                              />
                            </Timeline>
                            <Timeline
                              target={<img src={pic4} alt="错题讲解" />}
                              totalProgress={progress}
                              paused
                            >
                              <Tween
                                from={{ scale: 0.89 }}
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 1 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ scale: 0.89 }}
                                duration={DELAY_DISTANCE}
                              />
                            </Timeline>
                            <Timeline
                              target={<img src={pic5} alt="薄弱点专练" />}
                              totalProgress={progress}
                              paused
                            >
                              <Tween
                                from={{ scale: 0.89 }}
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 1 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ scale: 0.89 }}
                                duration={DELAY_DISTANCE}
                              />
                            </Timeline>
                            <Timeline
                              target={<img src={pic6} alt="举一反三" />}
                              totalProgress={progress}
                              paused
                            >
                              <Tween
                                from={{ scale: 0.89 }}
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 0.89 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ xPercent: '-=100', scale: 1 }}
                                delay={DELAY_DISTANCE}
                                duration={DURATION_DISTANCE}
                              />
                              <Tween
                                to={{ scale: 1 }}
                                duration={DELAY_DISTANCE}
                              />
                            </Timeline>
                          </div>
                        </div>
                      </div>
                      <div className="system-card-container-lg">
                        <div className="system-card-layer1-lg">
                          <Timeline
                            target={
                              <div className="system-card">
                                <div className="system-card-flex">
                                  <i className="megrez-icon-question" />
                                  <div
                                    className="system-title"
                                    style={{ color: 'inherit' }}
                                  >
                                    智能出题
                                  </div>
                                </div>
                                <div
                                  className="system-content"
                                  style={{ color: 'inherit' }}
                                >
                                  AI智能精准出题，只练不会的，不练重复的。
                                </div>
                              </div>
                            }
                            totalProgress={progress}
                          >
                            {/* <Tween
                              from={cardStyle.normal}
                              to={cardStyle.highlight}
                              delay={5}
                            /> */}
                            <Tween
                              from={cardStyle.highlight}
                              to={cardStyle.normal}
                              delay={cardDelayDistance(1)}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              duration={cardDurationDistance(0)}
                            />
                          </Timeline>
                          <img
                            src={arrow}
                            className="system-arrow-lg"
                            alt="arrow"
                          />
                          <Timeline
                            target={
                              <div className="system-card">
                                <div className="system-card-flex">
                                  <i className="megrez-icon-correct" />
                                  <div
                                    className="system-title"
                                    style={{ color: 'inherit' }}
                                  >
                                    AI批改
                                    <sup
                                      onClick={handleClickComment}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {commentIndex}
                                    </sup>
                                  </div>
                                </div>
                                <div
                                  className="system-content"
                                  style={{ color: 'inherit' }}
                                >
                                  一键AI自动批改，秒判错对，省时省力。
                                </div>
                              </div>
                            }
                            totalProgress={progress}
                          >
                            <Tween
                              from={cardStyle.normal}
                              to={cardStyle.highlight}
                              delay={cardDelayDistance(1)}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              delay={DELAY_DISTANCE + DURATION_DISTANCE}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              duration={cardDurationDistance(1)}
                            />
                          </Timeline>
                          <img
                            src={arrow}
                            className="system-arrow-lg"
                            alt="arrow"
                          />
                          <Timeline
                            target={
                              <div className="system-card">
                                <div className="system-card-flex">
                                  <i className="megrez-icon-induction" />
                                  <div
                                    className="system-title"
                                    style={{ color: 'inherit' }}
                                  >
                                    错题归纳
                                  </div>
                                </div>
                                <div
                                  className="system-content"
                                  style={{ color: 'inherit' }}
                                >
                                  错题自动收录整理，定期回顾，告别手动抄写粘贴的低效模式。
                                </div>
                              </div>
                            }
                            totalProgress={progress}
                          >
                            <Tween
                              from={cardStyle.normal}
                              to={cardStyle.highlight}
                              delay={cardDelayDistance(2)}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              delay={DELAY_DISTANCE + DURATION_DISTANCE}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              duration={cardDurationDistance(2)}
                            />
                          </Timeline>
                        </div>
                        <div className="system-arrow-layer">
                          <img
                            src={arrow}
                            className="system-arrow-lg"
                            alt="arrow"
                          />
                          <img
                            src={arrow}
                            className="system-arrow-lg"
                            alt="arrow"
                          />
                        </div>
                        <div className="system-card-layer2-lg">
                          <Timeline
                            target={
                              <div className="system-card">
                                <div className="system-card-flex">
                                  <i className="megrez-icon-explain" />
                                  <div
                                    className="system-title"
                                    style={{ color: 'inherit' }}
                                  >
                                    错题讲解
                                  </div>
                                </div>
                                <div
                                  className="system-content"
                                  style={{ color: 'inherit' }}
                                >
                                  错题分步骤引导解析，真人老师视频手把手启发解题，讲题过程还能倍数加速。
                                </div>
                              </div>
                            }
                            totalProgress={progress}
                          >
                            <Tween
                              from={cardStyle.normal}
                              to={cardStyle.highlight}
                              delay={cardDelayDistance(3)}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              delay={DELAY_DISTANCE + DURATION_DISTANCE}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              duration={cardDurationDistance(3)}
                            />
                          </Timeline>
                          <img
                            src={arrow}
                            className="system-arrow-lg"
                            alt="arrow"
                          />
                          <Timeline
                            target={
                              <div className="system-card">
                                <div className="system-card-flex">
                                  <i className="megrez-icon-practice" />
                                  <div
                                    className="system-title"
                                    style={{ color: 'inherit' }}
                                  >
                                    薄弱点专练
                                  </div>
                                </div>
                                <div
                                  className="system-content"
                                  style={{ color: 'inherit' }}
                                >
                                  精准定位薄弱点，自动生成个性化薄弱点专练，定期回顾攻克薄弱项。
                                </div>
                              </div>
                            }
                            totalProgress={progress}
                          >
                            <Tween
                              from={cardStyle.normal}
                              to={cardStyle.highlight}
                              delay={cardDelayDistance(4)}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              delay={DELAY_DISTANCE + DURATION_DISTANCE}
                              duration={0}
                            />
                            <Tween
                              to={cardStyle.normal}
                              duration={cardDurationDistance(4)}
                            />
                          </Timeline>
                          <img
                            src={arrow}
                            className="system-arrow-lg"
                            alt="arrow"
                          />
                          <Timeline
                            target={
                              <div className="system-card">
                                <div className="system-card-flex">
                                  <i className="megrez-icon-analogy" />
                                  <div
                                    className="system-title"
                                    style={{ color: 'inherit' }}
                                  >
                                    举一反三
                                  </div>
                                </div>
                                <div
                                  className="system-content"
                                  style={{ color: 'inherit' }}
                                >
                                  AI自动举一反三，一个知识点吃透练透。
                                </div>
                              </div>
                            }
                            totalProgress={progress}
                          >
                            <Tween
                              from={cardStyle.normal}
                              to={cardStyle.highlight}
                              delay={cardDelayDistance(5)}
                              duration={0}
                            />
                            {/* <Tween
                              to={cardStyle.normal}
                              delay={85}
                              duration={0}
                            /> */}
                            <Tween
                              to={cardStyle.highlight}
                              duration={cardDurationDistance(4)}
                            />
                          </Timeline>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
          </Controller>
        </section>
      ) : (
        <section className="system-container-sm">
          <h2>
            全场景「以练促学」精准学系统
            <br />
            薄弱点命中率
            <span
              className="system-highlight-sm"
              style={{ backgroundImage: `url(${headBG})` }}
            >
              95%
            </span>
            以上
          </h2>
          <div className="system-body-sm">
            <div className="system-card-container-sm">
              <div className="system-card-left-sm">
                <SystemCard
                  {...picData[0]}
                  handleClick={(id) => handleClickItem(id)}
                />
                <SystemCard
                  {...picData[1]}
                  title={
                    <>
                      AI批改<sup>10</sup>
                    </>
                  }
                  handleClick={(id) => handleClickItem(id)}
                />
                <SystemCard
                  {...picData[2]}
                  handleClick={(id) => handleClickItem(id)}
                />
              </div>
              <div className="system-card-right-sm">
                <SystemCard
                  {...picData[5]}
                  handleClick={(id) => handleClickItem(id)}
                />
                <SystemCard
                  {...picData[4]}
                  handleClick={(id) => handleClickItem(id)}
                />
                <SystemCard
                  {...picData[3]}
                  handleClick={(id) => handleClickItem(id)}
                />
              </div>
              <img
                src={arrowSm}
                className="system-arrow-1-sm system-arrow-sm"
                alt="arrow"
              />
              <img
                src={arrowSm}
                className="system-arrow-2-sm system-arrow-sm"
                alt="arrow"
              />
              <img
                src={arrowSm}
                className="system-arrow-3-sm system-arrow-sm"
                alt="arrow"
              />
              <img
                src={arrowSm}
                className="system-arrow-4-sm system-arrow-sm"
                alt="arrow"
              />
              <img
                src={arrowSm}
                className="system-arrow-5-sm system-arrow-sm"
                alt="arrow"
              />
              <img
                src={arrowSm}
                className="system-arrow-6-sm system-arrow-sm"
                alt="arrow"
              />
            </div>
            <div className="system-pic-container-sm">
              <div className="system-pic-title-sm">
                {picData[topIndex - 1].title}
              </div>
              <div className="system-pic-content-sm">
                {picData[topIndex - 1].content}
              </div>
              <div className="system-pic-wrap-sm">
                <img
                  className="system-pic-sm"
                  src={picData[topIndex - 1].pic}
                  alt="小猿学练机"
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
