/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { frog, object2Map, getType } from '@/services/frog'
import {
  JD_OFFICIAL_MALL_LINK,
  TAOBAO_OFFICIAL_MALL_LINK,
} from '@/constants/index'
import QrImg from '@/assets/qr.png'
import QrMobileImg from '@/assets/qr-mobile.png'
import useInserted from '@/utils/useInserted'
import MailIcon from './assets/mail-icon.png'
import StarIcon from './assets/star-icon.png'
import LocationIcon from './assets/location-icon.png'
import WechatIcon from './assets/wechat-icon.png'
import CloseIcon from './assets/close-icon.png'
import BuyIcon from './assets/buy_icon.png'
import HotlineIcon from './assets/hotline-icon.png'
import './Footer.less'

const EMAIL = 'bd@kanyun.com'
const HOTLINE = '400-0020-235'
const HOTLINE_TIMERANGE = '(周一至周日：10:00-21:00)'
const ADDRESS = '北京市平谷区林荫北街13号1幢12层1201-02'
const COPYRIGHT =
  '版权所有 © 北京寸阴成器科技有限公司 保留一切权利 ｜ ICP备案号：京ICP备2023017868号 ｜ 廉政举报邮箱：jubao@kanyun.com'

export default function Footer({ windowType }: { windowType: string }) {
  const [expand, setExpand] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  let firstRender = false
  useEffect(() => {
    firstRender = true
  }, [])

  const footerRef = useRef<any>(null)
  const insertedHandler = useCallback((ratio: number) => {
    if (ratio !== 0 && firstRender) {
      frog('/event/megrezOfficialPage/bottomModule/display', {
        keyValues: object2Map({
          type: getType(),
        }),
      })
      frog('/event/megrezOfficialPage/bottomModule/QRCodeDisplay', {
        keyValues: object2Map({
          type: getType(),
        }),
      })
      firstRender = false
    }
  }, [])

  useInserted({
    target: footerRef,
    handler: insertedHandler,
  })

  function onClickJD() {
    frog('/click/megrezOfficialPage/bottomModule/buy', {
      keyValues: object2Map({
        type: getType(),
        buyType: 'jingdong',
      }),
    })
    window.open(JD_OFFICIAL_MALL_LINK)
  }

  function onClickTB() {
    frog('/click/megrezOfficialPage/bottomModule/buy', {
      keyValues: object2Map({
        type: getType(),
        buyType: 'tianmao',
      }),
    })
    window.open(TAOBAO_OFFICIAL_MALL_LINK)
  }

  // function onClickExpand() {
  //   frog('/event/megrezOfficialPage/bottomModule/buyDisplay', {
  //     keyValues: object2Map({
  //       type: getType(),
  //     }),
  //   })
  //   setExpand(!expand)
  // }

  function onClickOpen() {
    frog('/event/megrezOfficialPage/bottomModule/QRCodeDisplay', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
    document.body.classList.toggle('footer-scroll-disabled')
    setOpen(true)
  }

  function onClickClose() {
    document.body.classList.toggle('footer-scroll-disabled')
    setOpen(false)
  }

  return (
    <div className={`footer--${windowType}`}>
      {['pc'].includes(windowType) ? (
        <footer className="footer-lg" ref={footerRef}>
          <div className="footer-flex">
            <div className="footer-left">
              <div className="footer-left-layer">
                <p className="footer-title">购买产品</p>
                <a onClick={onClickJD}>京东官方旗舰店</a>
                <a onClick={onClickTB}>天猫官方旗舰店</a>
                <img src={BuyIcon} alt="BuyIcon" />
              </div>

              <div className="footer-left-layer">
                <p className="footer-title">合作咨询</p>
                <p className="footer-content">{EMAIL}</p>
                <img src={MailIcon} alt="MailIcon" />
              </div>

              <div className="footer-left-layer">
                <p className="footer-title">客服热线</p>
                <p className="footer-content">{HOTLINE}</p>
                <p
                  className="footer-content"
                  style={{ transform: 'translateY(-1em)' }}
                >
                  {HOTLINE_TIMERANGE}
                </p>
                <img src={HotlineIcon} alt="HotlineIcon" />
              </div>

              <div className="footer-left-layer">
                <p className="footer-title">公司地址</p>
                <p className="footer-content">{ADDRESS}</p>
                <img src={LocationIcon} alt="LocationIcon" />
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-right-layer">
                <p className="footer-title-focus">关注我们</p>
                <img className="footer-qr" src={QrImg} alt="qr" />
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p>{COPYRIGHT}</p>
          </div>
        </footer>
      ) : (
        <footer className="footer-sm" ref={footerRef}>
          <div className="footer-layers">
            <div
              className="footer-layer"
              style={{
                margin: '0 12px',
                paddingLeft: '26px',
                paddingBottom: '16px',
                borderBottom: '1px solid rgba(255, 255, 255, 0.32)',
              }}
            >
              <p className="footer-title">购买产品</p>
              <p className="footer-content">
                <a onClick={onClickJD}>京东官方旗舰店</a>
              </p>
              <p className="footer-content">
                <a onClick={onClickTB}>天猫官方旗舰店</a>
              </p>
              <img src={BuyIcon} alt="BuyIcon" style={{ left: 0 }} />
            </div>

            <div className="footer-layer" style={{ marginTop: '9px' }}>
              <p className="footer-title">合作咨询</p>
              <p className="footer-content">{EMAIL}</p>
              <img src={MailIcon} alt="MailIcon" />
            </div>

            <div className="footer-layer">
              <p className="footer-title">客服热线</p>
              <p className="footer-content">{`${HOTLINE} ${HOTLINE_TIMERANGE}`}</p>
              <img src={HotlineIcon} alt="HotlineIcon" />
            </div>

            <div className="footer-layer">
              <p className="footer-title">公司地址</p>
              <p className="footer-content">{ADDRESS}</p>
              <img src={LocationIcon} alt="LocationIcon" />
            </div>

            <div className="footer-layer">
              <p className="footer-title">关注我们</p>
              <img src={StarIcon} alt="StarIcon" />
              <img
                className="footer-wechat"
                src={WechatIcon}
                alt="WechatIcon"
                onClick={onClickOpen}
              />
            </div>
          </div>

          <div className="footer-copyright">
            <p>{COPYRIGHT}</p>
          </div>

          <div
            className={`footer-wechat-mask ${
              open ? 'footer-wechat-mask-open' : ''
            }`}
            onClick={onClickClose}
          >
            <div className="footer-wechat-wrap">
              <img
                src={QrMobileImg}
                alt="QrMobileImg"
                onClick={(e) => e.stopPropagation()}
              />
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
        </footer>
      )}
    </div>
  )
}
