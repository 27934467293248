/* eslint-disable no-nested-ternary */
import type { MutableRefObject } from 'react'
import React, { useEffect, useState } from 'react'

type InsertedOption = {
  threshold?: [number, number]
}

type InsertHandler = (ratio: number, position: PositionRelativeViewport) => void

type TargetValue<T> = T | undefined | null
type TargetType = HTMLElement | Element

export type PositionRelativeViewport =
  | 'bottom'
  | 'top'
  | 'inside'
  | 'intersecting'

export type UseInsertedProps<T> = {
  target: MutableRefObject<TargetValue<T>>
  options?: InsertedOption
  handler?: InsertHandler
}

export default function useInserted<T extends TargetType>({
  target,
  ...rest
}: UseInsertedProps<T>) {
  const [ratio, setRatio] = useState<number>(0)
  const [state, setState] = useState<boolean>()
  const [position, setPosition] = useState<PositionRelativeViewport>('top')

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const ratioTemp = entry.intersectionRatio
          setRatio(ratioTemp)
          setState(entry.isIntersecting)
          if (entry.rootBounds) {
            const positionTemp =
              entry.boundingClientRect.top > entry.rootBounds.bottom
                ? 'bottom'
                : entry.boundingClientRect.bottom < entry.rootBounds.top
                  ? 'top'
                  : ratioTemp === 1
                    ? 'inside'
                    : 'intersecting'
            setPosition(positionTemp)
            if (typeof rest.handler === 'function') {
              rest.handler(ratioTemp, positionTemp)
            }
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: rest?.options?.threshold ?? [0, 1],
      }
    )

    if (target.current) {
      observer?.observe(target.current)
    }

    return () => {
      if (target.current) {
        observer?.unobserve(target.current)
      }
      observer.disconnect()
    }
  }, [target, rest.options, rest.handler])

  return [state, ratio, position]
}
