import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'
import middleImg from './assets/middle.png'
import Left1Img from './assets/left1.png'
import Left2Img from './assets/left2.png'
import Left3Img from './assets/left3.png'
import Left4Img from './assets/left4.png'
import Right1Img from './assets/right1.png'
import Right2Img from './assets/right2.png'
import Right3Img from './assets/right3.png'
import Right4Img from './assets/right4.png'
import middleImgSm from './assets/middle-sm.png'
import Left1ImgSm from './assets/left1-sm.png'
import Left2ImgSm from './assets/left2-sm.png'
import Left3ImgSm from './assets/left3-sm.png'
import Left4ImgSm from './assets/left4-sm.png'
import Right1ImgSm from './assets/right1-sm.png'
import Right2ImgSm from './assets/right2-sm.png'
import Right3ImgSm from './assets/right3-sm.png'
import Right4ImgSm from './assets/right4-sm.png'
import 'swiper/swiper.min.css'
import './SyncPractice.less'

export default function SyncPractice({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="animate sync-root-lg">
          {/* @ts-ignore */}
          <Controller>
            <div>
              {/* @ts-ignore */}
              <Scene triggerHook="onLeave" offset={-64} duration={1700} pin>
                {(progress: any) => (
                  <div className="sync-main">
                    <h2>教材同步练，贯通校内外</h2>
                    <h3 style={{ marginBottom: 0 }}>
                      结合猿辅导教学模型和AI技术智能出题，全学科覆盖
                    </h3>
                    <div className="sync-wrap">
                      <Timeline
                        target={
                          <img
                            className="sync-middle"
                            src={middleImg}
                            alt="小猿学练机"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{ scale: 1, xPercent: -50, yPercent: -50 }}
                          to={{
                            scale: 0.9,
                            xPercent: -50,
                            yPercent: -50,
                          }}
                          duration={500}
                        />
                        <Tween
                          to={{ scale: 0.9, xPercent: -50, yPercent: -50 }}
                          duration={800}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-left-1"
                            src={Left1Img}
                            alt="语文教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            translateY: '-50%',
                            translateX: '-50%',
                            scale: 0.9,
                          }}
                          to={{
                            scale: 0.9,
                            opacity: 1,
                            translateY: '-50%',
                            translateX: '-80%',
                          }}
                          delay={500}
                          duration={500}
                        />
                        <Tween to={{ scale: 0.9, opacity: 1 }} duration={800} />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-left-2"
                            src={Left2Img}
                            alt="英语教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-50%',
                          }}
                          to={{
                            opacity: 1,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-110%',
                          }}
                          delay={600}
                          duration={500}
                        />
                        <Tween to={{ opacity: 1, scale: 0.9 }} duration={800} />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-left-3"
                            src={Left3Img}
                            alt="物理教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-50%',
                          }}
                          to={{
                            opacity: 1,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-136%',
                          }}
                          delay={700}
                          duration={500}
                        />
                        <Tween to={{ opacity: 1, scale: 0.9 }} duration={800} />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-left-4"
                            src={Left4Img}
                            alt="化学教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-50%',
                          }}
                          to={{
                            opacity: 1,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-155%',
                          }}
                          delay={800}
                          duration={500}
                        />
                        <Tween to={{ opacity: 1, scale: 0.9 }} duration={800} />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-right-1"
                            src={Right1Img}
                            alt="地理教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-50%',
                          }}
                          to={{
                            opacity: 1,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-20%',
                          }}
                          delay={500}
                          duration={500}
                        />
                        <Tween to={{ opacity: 1, scale: 0.9 }} duration={800} />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-right-2"
                            src={Right2Img}
                            alt="历史教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-50%',
                          }}
                          to={{
                            opacity: 1,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '10%',
                          }}
                          delay={600}
                          duration={500}
                        />
                        <Tween to={{ opacity: 1, scale: 0.9 }} duration={800} />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-right-3"
                            src={Right3Img}
                            alt="政治教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-50%',
                          }}
                          to={{
                            opacity: 1,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '36%',
                          }}
                          delay={700}
                          duration={500}
                        />
                        <Tween to={{ opacity: 1, scale: 0.9 }} duration={800} />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="sync-right-4"
                            src={Right4Img}
                            alt="生物教材同步练习"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '-50%',
                          }}
                          to={{
                            opacity: 1,
                            scale: 0.9,
                            translateY: '-50%',
                            translateX: '55%',
                          }}
                          delay={800}
                          duration={500}
                        />
                        <Tween to={{ opacity: 1, scale: 0.9 }} duration={800} />
                      </Timeline>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
          </Controller>
        </section>
      ) : (
        <section className="animate sync-root-sm">
          <div className="sync-main">
            <h2>教材同步练，贯通校内外</h2>
            <h3>结合猿辅导教学模型和AI技术智能出题，全学科覆盖</h3>
            <Swiper
              centeredSlides
              slidesPerView="auto"
              onSlideChange={() => console.log('slide change')}
              initialSlide={4}
            >
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Left1ImgSm}
                  alt="地理教材同步练习"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Left2ImgSm}
                  alt="历史教材同步练习"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Left3ImgSm}
                  alt="政治教材同步练习"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Left4ImgSm}
                  alt="生物教材同步练习"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img className="sync-pic" src={middleImgSm} alt="小猿学练机" />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Right1ImgSm}
                  alt="语文教材同步练习"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Right2ImgSm}
                  alt="物理教材同步练习"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Right3ImgSm}
                  alt="英语教材同步练习"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="sync-pic"
                  src={Right4ImgSm}
                  alt="化学教材同步练习"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
      )}
    </>
  )
}
